<template>
  <div>
    <div>
      <!-- Filters -->
      <b-card>
        <div>
          <b-row class="mb-1">
            <b-col md="6" lg="6" xs="12">
              <b-form-input
                  v-on:keyup="onSearch"
                  v-model.lazy="searchTerm"
                  placeholder="Search by Title/ Company/ Email/ Contact Person/ Mobile"
                  type="text"
                  class="d-inline-block mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>
            <b-col md="6" lg="6" xs="12">

              <flat-pickr
                  v-model="filterRangeDate"
                  v-on:input="filterLeadsByDate"
                  class="form-control"
                  placeholder="Select Next Contact Date"
                  :config="{ mode: 'range', defaultDate: [this.filterStartDate, this.filterEndDate]}"
              />
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-card>
        <div>
          <b-row>
            <b-col md="6" lg="2" xs="12">
              <v-select
                  v-model="filterCountryId"
                  :options="filterCountryIdOption"
                  v-on:input="loadSelecterCountryLeads"
                  :reduce="(dept) => dept.id"
                  label="name"
                  placeholder="Select Country"
                  class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>
            <b-col md="6" lg="4" xs="12">
              <v-select
                  v-model="filterDepartmentId"
                  :options="filterDepartmentIdOption"
                  v-on:input="loadSelectedDepartmentLeads"
                  :reduce="(dept) => dept.id"
                  label="name"
                  placeholder="Select Department"
                  class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>

            <b-col md="6" lg="2" xs="12">
              <v-select
                  v-model="filterCategoryId"
                  :options="filterCategoryIdOption"
                  v-on:input="loadSelectedCategoryLeads"
                  :reduce="(category) => category.id"
                  label="name"
                  placeholder="Select Category"
                  class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>

            <b-col md="6" lg="2" xs="12">
              <v-select
                  v-model="filterStatusId"
                  :options="filterStatusIdOption"
                  v-on:input="loadSelectedStatusLeads"
                  :reduce="(status) => status.id"
                  label="name"
                  placeholder="Select Status"
                  class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>
            <b-col md="6" lg="2" xs="12">
              <v-select
                  v-model="filterSourceId"
                  :options="filterSourceIdOption"
                  v-on:input="loadSelectedSourceLeads"
                  :reduce="(source) => source.id"
                  label="name"
                  placeholder="Select Source"
                  class="mr-sm-1 mb-1 mb-sm-0 custom-font"
              />
            </b-col>
          </b-row>
        </div>
      </b-card>
    </div>
    <div>
      <b-card>
        <div>
          <div
              class="custom-search d-flex align-items-center justify-content-end"
          >
            <div
                class="d-flex flex-column flex-sm-row align-items-center mb-1 justify-content-around"
            >
              <template
                  v-if="$permissionAbility(MY_LEAD_CREATE, permissions)"
              >
                <b-button
                    class="flex-shrink-0"
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="primary"
                    v-on:click="showModal"
                >
                  Add Lead
                </b-button>
              </template>
            </div>
          </div>
          <!-- table -->
          <vue-good-table
              mode="remote"
              style-class="vgt-table table-custom-style bordered condensed"
              @on-page-change="onPageChange"
              @on-sort-change="onSortChange"
              @on-column-filter="onColumnFilter"
              @on-per-page-change="onPerPageChange"
              :line-numbers="false"
              :totalRows="totalRecords"
              :isLoading.sync="isLoading"
              :rows="rows"
              :sort-options="{
              enabled: false,
              multipleColumns: true,
              initialSortBy: [{ field: 'last_contact_date', type: 'desc' }],
            }"
              :columns="columns"
              :pagination-options="{
              enabled: true,
              perPage: pageLength,
            }"
              styleClass="vgt-table striped table-custom-style"
          >
            <template slot="table-row" slot-scope="props">
              <!-- title -->

              <template v-if="props?.column?.field === 'format_title'">
                <span>
                  <b>Title: </b>
                  <b-link v-on:click="onShowPage(props.row.id)">
                    {{ props?.row?.title }}
                  </b-link>
                </span>
                <div class="cell-with-description">
                  <div v-if="props?.row?.department">
                    <span>
                      <b>Dept: </b
                      >{{ props?.row?.department?.data?.name }}</span
                    >
                  </div>
                </div>
                <div class="cell-with-description">
                  <div v-if="props?.row?.leadSource">
                    <span>
                      <b>Source: </b
                      >{{ props?.row?.leadSource?.data?.name }}</span
                    >
                  </div>
                </div>
                <div class="cell-with-description">
                  <div v-if="props?.row?.leadCategory">
                    <span>
                      <b>Category: </b
                      >{{ props?.row?.leadCategory?.data?.name }}</span
                    >
                  </div>
                </div>
              </template>
              <!-- client_info -->
              <template v-if="props?.column?.field === 'client_info'">
                <div class="cell-with-description">
                  <div v-if="props?.row?.company_name">
                    <span
                    ><b>Company Name: </b>
                      {{ props?.row?.company_name }}</span
                    >
                  </div>
                </div>
                <div class="cell-with-description">
                  <div v-if="props?.row?.key_contact_person">
                    <span
                    ><b>Contact Person: </b>
                      {{ props?.row?.key_contact_person }}</span
                    >
                  </div>
                </div>
                <div class="cell-with-description">
                  <div v-if="props?.row?.email">
                    <span className="text-truncate text-muted mb-0">
                      <b>Email: </b>
                      <b-link :href="'mailto:' + props?.row?.email">
                        {{ props?.row?.email }}
                      </b-link>
                    </span>
                  </div>
                </div>
                <div class="cell-with-description">
                  <div v-if="props?.row?.mobile">
                    <b>Mobile: </b
                    ><span className="text-truncate text-muted mb-0">
                      <b-link :href="'tel:' + props?.row?.mobile">
                        {{ props?.row?.mobile }}
                      </b-link></span
                  >
                  </div>
                </div>
                <div class="cell-with-description">
                  <div v-if="props?.row?.leadCountry">
                    <b>Country: </b>
                    <span className="fw-bolder">
                      {{ props?.row?.leadCountry?.data?.name }}
                    </span>
                  </div>
                </div>
              </template>

              <template v-if="props?.column?.field === 'format_member_info'">
              <span v-for="(member, index) in props.row.team.data" :key="index">
                <template v-if="index <= 2">
                  <b-avatar :src="member?.avatar === '' ? '/avatar.svg' : member?.avatar" size="26" style="margin: 1px"
                  />
                </template>
              </span>
                <span v-if="props?.row?.team_count > 3">
                + {{ props?.row?.team_count - 3 }} more
              </span>
                <b-button
                    variant="outline-primary"
                    class="btn-icon rounded-circle"
                    style="margin-left: 5px"
                    v-on:click="showAllAddedMemberModal(props?.row?.team?.data)"
                >
                  <feather-icon icon="UsersIcon" />
                </b-button>
              </template>

              <template v-if="props?.column?.field === 'format_user'">
                <div class="cell-with-description">
                  <span>
                    <b-avatar
                        :src="props?.row?.user?.data?.avatar === '' ? '/avatar.svg' : props?.row?.user?.data?.avatar"
                        class="mx-1"
                    />
                  </span>
                </div>

                <div class="cell-with-description">
                  <div v-if="props?.row?.user?.data?.name">
                    <span className="fw-bolder">
                      {{ props?.row?.user?.data?.name }}
                    </span>
                  </div>
                </div>

                <div class="cell-with-description">
                  <div v-if="props?.row?.user?.data?.email">
                    <span className="text-truncate text-muted mb-0">
                      <b>Email: </b>
                      <b-link :href="'mailto:' + props?.row?.user?.data?.email">
                        {{ props?.row?.user?.data?.email }}
                      </b-link>
                    </span>
                  </div>
                </div>
                <div class="cell-with-description">
                  <div v-if="props?.row?.user?.data?.employee_number">
                    <span>
                      {{ props?.row?.user?.data?.employee_number }}
                    </span>
                  </div>
                </div>
              </template>
              <!-- schedule -->
              <template
                  v-if="props?.column?.field === 'format_contact_schedule'"
              >
                <div class="cell-with-description">
                  <div v-if="props?.row?.last_contact_date">
                    <span className="text-truncate text-muted mb-0">
                      <b> Last Contacted : </b>
                      <b-badge pill>
                        {{
                          formatFnTableLastContactDate(
                              props?.row?.last_contact_date
                          )
                        }}
                      </b-badge>
                    </span>
                  </div>
                </div>
                <div class="cell-with-description">
                  <div>
                    <span className="text-truncate text-muted mb-0">
                      <b> Next Contact: </b>
                      <b-badge pill>
                        {{
                          formatFnTableLastContactDate(
                              props?.row?.next_contact_date
                          )
                        }}
                      </b-badge>
                    </span>
                  </div>
                </div>
              </template>
              <!-- status -->
              <template v-if="props?.column?.field === 'format_status'">
                <div class="cell-with-description">
                  <div v-if="props?.row?.leadStatus?.data?.name">
                    <span className="text-truncate text-muted mb-0">
                      <b-badge
                          class="badge-glow"
                          :style="{
                          backgroundColor:
                            props?.row?.leadStatus?.data?.color_code,
                        }"
                      >
                        {{ props?.row?.leadStatus?.data?.name }}
                      </b-badge>
                    </span>
                  </div>
                </div>
                <div class="cell-with-description">
                  <template
                      v-if="
                      props?.row?.estimation_sent == true &&
                      props?.row?.leadStatus?.data?.type == 2
                    "
                  >
                    <b-badge class="badge-glow mt-1" variant="warning">
                      Estimation Sent
                    </b-badge>
                  </template>
                  <template
                      v-else-if="
                      props?.row?.estimation_sent == false &&
                      props?.row?.leadStatus?.data?.type == 2
                    "
                  >
                    <b-badge class="badge-glow mt-1" variant="danger">
                      Estimation Not Sent
                    </b-badge>
                    <br />
                    <b-button
                        class="btn-sm mt-1 mb-1 custom-font"
                        v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                        variant="success"
                        v-on:click="showEstimationModal(props?.row)"
                    >
                      Submit Estimation
                    </b-button>
                  </template>
                </div>
              </template>

              <!-- Column: Action -->
              <span v-if="props.column.field === 'action'">
                <span v-if="$permissionAbility(MY_LEAD_EDIT, permissions) || $permissionAbility(MY_LEAD_DELETE, permissions)">
                  <b-dropdown
                      variant="link"
                      toggle-class="text-decoration-none"
                      no-caret
                  >
                    <template v-slot:button-content>
                      <feather-icon
                          icon="MoreVerticalIcon"
                          size="16"
                          class="text-body align-middle mr-25"
                      />
                    </template>

                    <template
                        v-if="$permissionAbility(MY_LEAD_EDIT, permissions)"
                    >
                      <b-dropdown-item v-on:click="onShowPage(props.row.id)">
                        <feather-icon icon="EyeIcon" class="mr-50" />
                        <span>Show</span>
                      </b-dropdown-item>

                      <b-dropdown-item v-on:click="() => onCopy(props.row)">
                        <feather-icon icon="CopyIcon" class="mr-50" />
                        <span>Copy</span>
                      </b-dropdown-item>
                    </template>

                    <template
                        v-if="$permissionAbility(MY_LEAD_EDIT, permissions)"
                    >
                      <b-dropdown-item v-on:click="onShow(props.row)">
                        <feather-icon icon="Edit2Icon" class="mr-50" />
                        <span>Edit</span>
                      </b-dropdown-item>
                    </template>

                    <template
                        v-if="
                        $permissionAbility(MY_LEAD_DELETE, permissions)
                      "
                    >
                      <b-dropdown-item v-on:click="onDelete(props.row.id)">
                        <feather-icon icon="TrashIcon" class="mr-50" />
                        <span>Delete</span>
                      </b-dropdown-item>
                    </template>
                  </b-dropdown>
                </span>
              </span>

              <!-- Column: Common -->
              <span v-else>
                {{ props.formattedRow[props.column.field] }}
              </span>
            </template>

            <!-- pagination -->
            <template slot="pagination-bottom" slot-scope="props">
              <div class="d-flex justify-content-between flex-wrap">
                <div class="d-flex align-items-center mb-0 mt-1">
                  <span class="text-nowrap"> Showing 1 to </span>
                  <b-form-select
                      v-model="pageLength"
                      :options="['10', '25', '50', '100', '500']"
                      class="mx-1"
                      @input="
                      (value) => props.perPageChanged({ currentPerPage: value })
                    "
                  />
                  <span class="text-nowrap">
                    of {{ props.total }} entries
                  </span>
                </div>
                <div>
                  <b-pagination
                      :value="1"
                      :total-rows="props.total"
                      :per-page="pageLength"
                      first-number
                      last-number
                      align="right"
                      prev-class="prev-item"
                      next-class="next-item"
                      class="mt-1 mb-0"
                      @input="
                      (value) => props.pageChanged({ currentPage: value })
                    "
                  >
                    <template #prev-text>
                      <feather-icon icon="ChevronLeftIcon" size="18" />
                    </template>
                    <template #next-text>
                      <feather-icon icon="ChevronRightIcon" size="18" />
                    </template>
                  </b-pagination>
                </div>
              </div>
            </template>
          </vue-good-table>

          <!-- table -->
        </div>
      </b-card>
    </div>

    <b-modal
        id="modal-all-added-member-form"
        centered
        title="Assigned Members"
        hide-footer
        @hidden="hiddenAllAddedMemberModal"
        no-close-on-backdrop
    >
      <ul>
        <li v-for="(member, index) in assingedMember" :key="index">
          <b-avatar :src="member?.avatar === '' ? '/avatar.svg' : member?.avatar" size="26" style="margin: 1px" />
          {{ member.name }}
        </li>
      </ul>
    </b-modal>

    <!-- Lead Modal -->
    <b-modal
        id="modal-lead-form"
        centered
        :title="
        modelType == 'editModel'
          ? 'Edit Lead  Details '
          : modelType == 'copyModel'
          ? 'Copy Lead '
          : 'Create Lead '
      "
        hide-footer
        @hidden="hiddenModal"
        size="lg"
        no-close-on-backdrop
    >
      <validation-observer ref="leadValidation">
        <b-form v-on:submit.prevent="validationForm">
          <b-row>
            <b-col md="12" lg="12" xs="12">
              <!-- title  -->
              <b-form-group
                  label="Lead Title "
                  label-for="title"
                  class="required-label"
              >
                <validation-provider
                    #default="{ errors }"
                    name="title"
                    vid="title"
                    rules="required|max:255"
                >
                  <b-form-input
                      id="name"
                      type="text"
                      v-model="title"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Lead Title"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row class="mt-2 mb-1">
            <b-col md="12" lg="12" xs="12"> <h5>Client Information</h5></b-col>
          </b-row>
          <b-row>
            <b-col md="4" lg="4" xs="12">
              <b-form-group
                  label="Company Name "
                  label-for="company_name"
                  class="required-label"
              >
                <validation-provider
                    #default="{ errors }"
                    name="company name"
                    vid="company_name"
                    rules="required|max:255"
                >
                  <b-form-input
                      id="name"
                      type="text"
                      v-model="company_name"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Company Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="4" lg="4" xs="12">
              <b-form-group label="Email " label-for="email">
                <validation-provider
                    #default="{ errors }"
                    name="company email"
                    vid="email"
                    rules="email"
                >
                  <b-form-input
                      id="name"
                      type="email"
                      v-model="email"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Contact Email"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="4" lg="4" xs="12">
              <b-form-group
                  label="Country "
                  label-for="country_id"
                  class="required-label"
              >
                <ValidationProvider
                    name="country name"
                    v-slot="{ errors }"
                    vid="country_id"
                    rules="required"
                >
                  <v-select
                      id="division-id"
                      placeholder="Choose here"
                      v-model="country_id"
                      :options="countryIdOption"
                      :reduce="(item) => item?.id"
                      v-on:input="setDialCodeAsCountry"
                      label="name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="6" lg="7" xs="12">
              <b-form-group label="Company Address" label-for="address">
                <validation-provider
                    #default="{ errors }"
                    name="company address"
                    vid="address"
                    rules="max:255"
                >
                  <b-form-textarea
                      id="address"
                      v-model="address"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Company Address"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="6" lg="5" xs="12">
              <b-form-group
                  label="Company Website Link "
                  label-for="website_link"
              >
                <validation-provider
                    #default="{ errors }"
                    name="website link"
                    vid="website_link"
                >
                  <b-form-input
                      id="website_link"
                      type="text"
                      v-model="website_link"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Website Link"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row>
            <b-col md="3" lg="3" xs="12">
              <b-form-group label="City" label-for="city">
                <validation-provider
                    #default="{ errors }"
                    name="city"
                    vid="city"
                    rules="max:255"
                >
                  <b-form-input
                      id="name"
                      type="text"
                      v-model="city"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter City Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3" lg="3" xs="12">
              <b-form-group label="State " label-for="state">
                <validation-provider
                    #default="{ errors }"
                    name="state"
                    vid="state"
                >
                  <b-form-input
                      id="state"
                      type="text"
                      v-model="state"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter State Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3" lg="3" xs="12">
              <b-form-group label="Street " label-for="street">
                <validation-provider
                    #default="{ errors }"
                    name="street"
                    vid="street"
                >
                  <b-form-input
                      id="street"
                      type="text"
                      v-model="street"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Street Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col md="3" lg="3" xs="12">
              <b-form-group label="Zip Code " label-for="zip_code">
                <validation-provider
                    #default="{ errors }"
                    name="zip code"
                    vid="zip_code"
                >
                  <b-form-input
                      id="zip_code"
                      type="text"
                      v-model="zip_code"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Zip Code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2 mb-1">
            <b-col md="12" lg="12" xs="12">
              <h5>Key Contact Person Information</h5></b-col
            >
          </b-row>
          <b-row>
            <b-col md="3" lg="3" xs="12">
              <b-form-group
                  label="Person Name "
                  label-for="key_contact_person"
                  class="required-label"
              >
                <validation-provider
                    #default="{ errors }"
                    name="person name"
                    vid="key_contact_person"
                    rules="required|max:255"
                >
                  <b-form-input
                      id="key_contact_person"
                      type="text"
                      v-model="keyContactPerson"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Person Name"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3" lg="3" xs="12">
              <b-form-group
                  label="Dial Code "
                  label-for="dial_code_id"
                  class="required-label"
              >
                <ValidationProvider
                    name="dial code"
                    v-slot="{ errors }"
                    vid="dial_code_country_id"
                    :rules="mobile ? 'required' : ''"
                >
                  <v-select
                      id="division-id"
                      placeholder="Choose here"
                      v-model="dial_code_country_id"
                      :options="dialCodeCountryIdOption"
                      :reduce="(item) => item?.id"
                      label="dial_code"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </ValidationProvider>
              </b-form-group>
            </b-col>
            <b-col md="3" lg="3" xs="12">
              <!-- mobile -->
              <b-form-group label="Mobile" label-for="mobile">
                <validation-provider
                    #default="{ errors }"
                    name="mobile"
                    vid="mobile"
                >
                  <b-form-input
                      id="name"
                      type="number"
                      v-model="mobile"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <b-col md="3" lg="3" xs="12">
              <!-- phone  -->
              <b-form-group label="Tel " label-for="phone">
                <validation-provider
                    #default="{ errors }"
                    name="tel"
                    vid="phone"
                    rules="numeric"
                >
                  <b-form-input
                      id="name"
                      type="number"
                      v-model="phone"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Enter Number"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>

          <b-row class="mt-2 mb-1">
            <b-col md="12" lg="12" xs="12">
              <h5>Lead Assignee Information</h5></b-col
            >
          </b-row>

          <template v-if="modelType == 'editModel'">
            <b-row>
              <b-col v-show="false" md="6" lg="6" xs="12">
                <!-- select user  -->
                <b-form-group
                    label="Assigned Employee "
                    label-for="assign_users"
                    class="required-label"
                >
                  <ValidationProvider
                      name="assigned employee"
                      v-slot="{ errors }"
                      vid="assign_users"
                  >
                    <v-select
                        id="user-id"
                        placeholder="Assign Employee"
                        v-model="assign_users"
                        :options="userIdOption"
                        :reduce="(item) => item?.id"
                        label="name"
                        multiple
                        disabled
                    >
                      <template #option="data">
                        <div class="d-flex align-items-center">
                          <b-avatar :src="data?.avatar === '' ? '/avatar.svg' : data?.avatar" class="mr-1">
                          </b-avatar>
                          <div>
                            <div class="font-weight-bolder">
                              {{ data?.name }}
                              <b-badge pill variant="warning">{{
                                  data?.employee_number
                                }}</b-badge>
                            </div>
                            <div class="font-small-2">
                              {{ data?.email }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="6" lg="6" xs="12">
                <!-- select source-->
                <b-form-group
                    label="Lead Source "
                    label-for="lead_source_id"
                    class="required-label"
                >
                  <ValidationProvider
                      name="lead source"
                      v-slot="{ errors }"
                      vid="lead_source_id"
                      rules="required"
                  >
                    <v-select
                        id="head-id"
                        placeholder="Choose a Lead Source"
                        v-model="lead_source_id"
                        :options="sourceIdOption"
                        :reduce="(item) => item?.id"
                        label="name"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6" lg="6" xs="12">
                <b-form-group
                    label="Lead Category"
                    label-for="lead_category_id"
                    class="required-label"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="lead category"
                      vid="lead_category_id"
                      rules="required"
                  >
                    <v-select
                        id="head-id"
                        placeholder="Choose a Category"
                        v-model="lead_category_id"
                        :options="categoryIdOption"
                        :reduce="(item) => item?.id"
                        label="name"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="6" lg="6" xs="12">
                <b-form-group
                    label="Department "
                    label-for="department_id"
                    class="required-label"
                >
                  <ValidationProvider
                      name="department name"
                      v-slot="{ errors }"
                      vid="department_id"
                      rules="required"
                  >
                    <v-select
                        id="division-id"
                        placeholder="Choose a Department"
                        v-model="department_id"
                        :options="departmentIdOption"
                        :reduce="(item) => item?.id"
                        label="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
          </template>
          <template v-else>
            <b-row>
              <b-col v-show="false" md="6" lg="6" xs="12">
                <b-form-group
                    label="Assigned Employee "
                    label-for="assign_users"
                    class="required-label"
                >
                  <ValidationProvider
                      name="assigned employee"
                      v-slot="{ errors }"
                      vid="assign_users"
                      rules="required"
                  >
                    <v-select
                        id="user-id"
                        placeholder="Assign Employee"
                        v-model="assign_users"
                        :options="userIdOption"
                        :reduce="(item) => item?.id"
                        label="name"
                        multiple
                        disabled
                    >
                      <template #option="data">
                        <div class="d-flex align-items-center">
                          <b-avatar :src="data?.avatar === '' ? '/avatar.svg' : data?.avatar" class="mr-1">
                          </b-avatar>
                          <div>
                            <div class="font-weight-bolder">
                              {{ data?.name }}
                              <b-badge pill variant="warning">{{
                                  data?.employee_number
                                }}</b-badge>
                            </div>
                            <div class="font-small-2">
                              {{ data?.email }}
                            </div>
                          </div>
                        </div>
                      </template>
                    </v-select>
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>

              <b-col md="6" lg="6" xs="12">
                <b-form-group
                    label="Lead Status "
                    label-for="lead_status_id"
                    class="required-label"
                >
                  <ValidationProvider
                      name="lead status"
                      v-slot="{ errors }"
                      vid="lead_status_id"
                      rules="required"
                  >
                    <v-select
                        id="division-id"
                        placeholder="Assign a Status"
                        v-model="lead_status_id"
                        :options="statusIdOption"
                        :reduce="(item) => item.id"
                        label="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
            <b-row>
              <b-col md="4" lg="4" xs="12">
                <!-- select source-->
                <b-form-group
                    label="Lead Source "
                    label-for="lead_source_id"
                    class="required-label"
                >
                  <ValidationProvider
                      name="lead source"
                      v-slot="{ errors }"
                      vid="lead_source_id"
                      rules="required"
                  >
                    <v-select
                        id="head-id"
                        placeholder="Choose a Source"
                        v-model="lead_source_id"
                        :options="sourceIdOption"
                        :reduce="(item) => item?.id"
                        label="name"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
              <b-col md="4" lg="4" xs="12">
                <b-form-group
                    label="Lead Category"
                    label-for="lead_category_id"
                    class="required-label"
                >
                  <validation-provider
                      #default="{ errors }"
                      name="lead category"
                      vid="lead_category_id"
                      rules="required"
                  >
                    <v-select
                        id="head-id"
                        placeholder="Choose a Category"
                        v-model="lead_category_id"
                        :options="categoryIdOption"
                        :reduce="(item) => item?.id"
                        label="name"
                    />

                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
              </b-col>

              <b-col md="4" lg="4" xs="12">
                <b-form-group
                    label="Department "
                    label-for="department_id"
                    class="required-label"
                >
                  <ValidationProvider
                      name="department name"
                      v-slot="{ errors }"
                      vid="department_id"
                      rules="required"
                  >
                    <v-select
                        id="division-id"
                        placeholder="Choose a Department"
                        v-model="department_id"
                        :options="departmentIdOption"
                        :reduce="(item) => item?.id"
                        label="name"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </ValidationProvider>
                </b-form-group>
              </b-col>
            </b-row>
          </template>

          <b-row>
            <b-col cols="12">
              <!-- Note  -->
              <b-form-group label="Note" label-for="note">
                <validation-provider
                    #default="{ errors }"
                    name="note"
                    vid="note"
                    rules="max:255"
                >
                  <b-form-textarea
                      id="note"
                      v-model="note"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Take A Quick Note"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
          </b-row>
          <!-- -->

          <b-row>
            <b-col cols="12">
              <b-row>

                <b-col cols="6">
                  <template v-if="modelType === 'editModel'">

                    <b-img
                        :src="business_card_front"
                        class="small-image"
                    ></b-img>

                  </template>
                </b-col>
                <b-col cols="6">
                  <template v-if="modelType === 'editModel'">
                    <b-img
                        :src="business_card_back"
                        class="small-image"
                    ></b-img>
                  </template>

                </b-col>

                <b-col md="6">
                  <b-form-group
                      label="Business Card (Front)"
                      label-for="business_card_front"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="Business Card (Front)"
                        rules="size:2048|ext:jpeg,png,jpg"
                        vid="business_card_front"
                    >
                      <b-form-file
                          id="business_card_front"
                          v-model="business_card_front_input"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>

                </b-col>

                <b-col md="6">

                  <b-form-group
                      label="Business Card (Back)"
                      label-for="business_card_back"
                  >
                    <validation-provider
                        #default="{ errors }"
                        name="Business Card (Back)"
                        rules="size:2048|ext:jpeg,png,jpg"
                        vid="business_card_back"
                    >
                      <b-form-file
                          id="business_card_back"
                          v-model="business_card_back_input"
                          :state="errors.length > 0 ? false : null"
                          placeholder="Choose a file or drop it here..."
                          drop-placeholder="Drop file here..."
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-col>
          </b-row>

          <!-- loading button -->
          <template v-if="isLeadsFormSubmitLoading">
            <b-button class="float-right" variant="primary" disabled>
              <b-spinner small />
              Loading...
            </b-button>
          </template>

          <!-- submit button -->
          <template v-else>
            <b-button
                type="submit"
                class="float-right"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
            >
              Submit
            </b-button>
          </template>
        </b-form>
      </validation-observer>
    </b-modal>

    <!-- Financial Estimation Modal -->
    <FinancialEstimationForm
        :selectedLeadInfo="selectedLeadInfo"
        :leadTitle="leadTitle"
        v-on:loadLeadInfo="updateLeadInfo($event)"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BAvatar,
  BBadge,
  BPagination,
  BFormGroup,
  BFormInput,
  BFormSelect,
  BDropdown,
  BDropdownItem,
  BButton,
  BForm,
  BModal,
  BSpinner,
  BFormTextarea,
  BFormDatepicker,
  BLink,
  BCardHeader,
  BCardTitle,
  BCardText,
  BCardBody,
  BMedia,
  BMediaAside,
  BMediaBody,
  VBTooltip, BImg, BFormFile,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import Ripple from "vue-ripple-directive";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import {
  required as validate_required,
  max,
  size,
  ext,
  numeric as validate_numeric,
} from "@validations";
import flatPickr from "vue-flatpickr-component";
import FinancialEstimationForm from "@/views/admin/finance/project/FinancialEstimationForm.vue";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { mapGetters } from "vuex";

import { projectTypeConstants } from "@/helpers/constant/projectTypeConstant";
import { paymentTypeConstants } from "@/helpers/constant/paymentTypeConstant";
import {
  LEAD_MANAGEMENT_ACCESS,
  LEAD_DETAILS_EXPORT,
  LEAD_DETAILS_CREATE,
  LEAD_DETAILS_EDIT,
  LEAD_DETAILS_DELETE,
  MY_LEAD_EDIT,
  MY_LEAD_DELETE,
  MY_LEAD_CREATE,
} from "@/helpers/permissionsConstant";

export default {
  name: "LeadView",
  components: {
    BFormFile, BImg,
    BLink,
    BForm,
    BButton,
    BCard,
    VueGoodTable,
    BAvatar,
    BBadge,
    BPagination,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BDropdown,
    BDropdownItem,
    ValidationProvider,
    ValidationObserver,
    BModal,
    BSpinner,
    BRow,
    BCol,
    BFormTextarea,
    BFormDatepicker,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    flatPickr,
    BMedia,
    BMediaAside,
    BMediaBody,
    FinancialEstimationForm,
    VBTooltip,
  },
  directives: {
    Ripple,
    "b-tooltip": VBTooltip,
  },
  data() {
    return {
      MY_LEAD_EDIT,
      MY_LEAD_DELETE,
      assingedMember: [],
      LEAD_MANAGEMENT_ACCESS,
      LEAD_DETAILS_EXPORT,
      LEAD_DETAILS_CREATE,
      MY_LEAD_CREATE,
      LEAD_DETAILS_EDIT,
      LEAD_DETAILS_DELETE,
      //Custom Validation
      amountNotMatch: false,
      amountNotMatchError: "",
      vatNotMatch: false,
      vatNotMatchError: "",
      taxNotMatch: false,
      taxNotMatchError: "",
      estimationSubmitDisabled: false,
      // Financial Estimation
      showFinancialEstimationComponent: false,
      selectedLeadInfo: {},
      leadTitle: "",
      projectTypeConstants,
      paymentTypeConstants,
      projectType: "",
      paymentType: "",
      amount: "",
      timeDuration: "",
      vatAmount: "",
      taxAmount: "",
      grandAmount: "",
      milestones: "",
      milestoneAmmount: [],
      milestoneVat: [],
      milestoneTax: [],
      milestoneTotal: [],

      filterRangeDate: "",

      milestoneDeadLine: [],
      milestonePaymentType: [],
      isMileStoneLoading: false,
      isFinancialEstimationFormLoading: false,

      //Lead
      leadId: "",
      modelType: "",
      lead_category_id: "",
      categoryIdOption: [],
      lead_source_id: "",
      sourceIdOption: [],
      department_id: "",
      departmentIdOption: [],
      dial_code_country_id: "",
      dialCodeCountryIdOption: [],
      lead_status_id: "",
      statusIdOption: [],
      assign_users: [],
      userIdOption: [],
      title: "",
      keyContactPerson: "",
      company_name: "",
      country_id: "",
      countryIdOption: [],
      email: "",
      mobile: "",
      phone: "",
      address: "",
      city: "",
      state: "",
      street: "",
      zip_code: "",
      note: "",
      website_link: "",
      leadStatusInfo: [],

      business_card_front_input: null,
      business_card_back_input: null,
      business_card_front: null,
      business_card_back: null,

      //filter options
      filterSourceId: "",
      filterSourceIdOption: [],
      filterStatusId: "",
      filterStatusIdOption: [],
      filterCategoryId: "",
      filterCategoryIdOption: [],
      filterEmpId: "",
      filterEmpIdOption: [],
      filterDepartmentId: "",
      filterDepartmentIdOption: [],
      filterCountryId: "",
      filterCountryIdOption: [],
      filterStartDate: "",
      filterEndDate: "",
      params: [],
      pageLength: 50,
      columns: [
        {
          label: "Lead Info",
          field: "format_title",
          sortable: false,
        },
        {
          label: "Client Info",
          field: "client_info",
          sortable: false,
        },


        {
          label: "Contact Schedule",
          field: "format_contact_schedule",
          sortable: false,
        },
        {
          label: "Assigned Employee",
          field: "format_member_info",
          sortable: false,
        },

        {
          label: "Status",
          field: "format_status",
          sortable: false,
        },

        {
          label: "Created",
          field: "created_at",
          sortable: false,
        },
        {
          label: "Action",
          field: "action",
          sortable: false,
        },
      ],
      rows: [],
      searchTerm: "",
      sourceId: "",
      statusId: "",
      categoryId: "",
      empId: "",
      departmentId: "",
      delayTimer: null,
      isLoading: false,
      isLeadsFormSubmitLoading: false,
      totalRecords: 0,
      serverParams: {
        columnFilters: {},
        sort: [
          // { field: "id", type: "desc" },
          // { field: "name", type: "desc" },
          { field: "created_at", type: "desc" },
        ],
        page: 1,
        perPage: 50,
      },
    };
  },

  computed: {
    ...mapGetters({
      filters: "leadModule/getFilters",
      permissions: "userModule/getPermissions",
      authUser: "userModule/getUser"
    }),
  },

  async created() {
    try {

      const [
        categories,
        sources,
        departments,
        status,
        countries,
        users,
        leadStatus,
      ] = await Promise.all([
        this.getLeadCategories(),
        this.getLeadSources(),
        this.getDepartments(),
        this.getLeadStatus(),
        this.getCountries(),
        this.getUsers(),
        this.getLeadStatusInfo(),
      ]);

      this.leadStatusInfo = (leadStatus?.data?.data || []).map((item) => {
        let name = item.name;
        let leadCount = item.leads_count;
        let leadColorCode = item.color_code;

        return {
          name: name,
          leadCount: leadCount,
          leadColorCode: leadColorCode,
        };
      });
      // categories
      this.categoryIdOption = (categories?.data?.data || []).map((item) => {
        let name = item.name;

        return {
          name,
          id: item.id,
        };
      });

      // sources
      this.sourceIdOption = (sources?.data?.data || []).map((item) => {
        let name = item.name;

        return {
          name,
          id: item.id,
        };
      });

      // department
      this.departmentIdOption = (departments?.data?.data || []).map((item) => {
        let name = item.name;
        return {
          name,
          id: item.id,
        };
      });

      //country
      this.countryIdOption = (countries?.data?.data || []).map((item) => {
        let name = item.name;

        return {
          name,
          id: item.id,
        };
      });

      this.userIdOption = (users?.data?.data || []).map((item) => {
        return {
          name: item?.name,
          email: item?.email,
          mobile: item?.mobile,
          employee_number: item?.employee_number,
          id: item?.id,
          departmentId: item?.department_id,
        }
      });
      //dial code
      this.dialCodeCountryIdOption = (countries?.data?.data || []).map(
          (item) => {
            let dial_code = item.dial_code;
            return {
              dial_code,
              id: item.id,
            };
          }
      );
      //filter sources option
      this.filterSourceIdOption = (sources?.data?.data || []).map((item) => {
        let name = item.name;

        return {
          name,
          id: item.id,
        };
      });

      //filter status option
      this.filterStatusIdOption = (status?.data?.data || []).map((item) => {
        let name = item.name;
        return {
          name,
          id: item.id,
        };
      });

      //filter category option
      this.filterCategoryIdOption = (categories?.data?.data || []).map(
          (item) => {
            let name = item.name;

            return {
              name,
              id: item.id,
            };
          }
      );

      //filter emp option
      this.filterEmpIdOption = (users?.data?.data || []).map((item) => {
        return {
          name: item?.name,
          email: item?.email,
          mobile: item?.mobile,
          employee_number: item?.employee_number,
          id: item?.id,
          departmentId: item?.department_id,
        }
      });

      //filter department option
      this.filterDepartmentIdOption = (departments?.data?.data || []).map(
          (item) => {
            let name = item.name;
            return {
              name,
              id: item.id,
            };
          }
      );

      //filter country option
      this.filterCountryIdOption = (countries?.data?.data || []).map((item) => {
        let name = item.name;
        let dial_code = item.dial_code;
        return {
          name,
          dial_code,
          id: item.id,
        };
      });

      // getting the filters value from vue store
      // this.filterSourceId = this.filters?.filterSourceId;
      // this.filterStatusId = this.filters?.filterStatusId;
      // this.filterEmpId = this.filters?.filterEmpId;
      // this.filterCategoryId = this?.filters?.filterCategoryId;
      // this.filterDepartmentId = this.filters?.filterDepartmentId;
      // this.filterCountryId = this.filters?.filterCountryId;
      // this.filterStartDate = this.filters?.filterStartDate;
      // this.filterEndDate = this.filters?.filterEndDate;
      // this.searchTerm = this.filters?.searchTerm;
    } catch (error) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: "Error",
          icon: "BellIcon",
          variant: "danger",
          text: error?.response?.data?.message,
        },
      });
    }
  },

  methods: {

    showAllAddedMemberModal(value) {
      this.assingedMember = value.map((item) => {
        let name = `${item?.name} (${item?.email})`;
        return {
          name,
          id: item.id,
          avatar: item?.avatar,
        };
      });

      this.$bvModal.show("modal-all-added-member-form");
    },

    hiddenAllAddedMemberModal() {
      this.$bvModal.hide("modal-all-added-member-form");
    },

    handleDateRangeChange()
    {
      if (this.filterRangeDate && this.filterRangeDate !== '') {
        const dates = this.filterRangeDate.split(' to ');

        if (dates.length === 1) {
          // Case where only one date is provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[0];
        } else if (dates.length === 2) {
          // Case where both start and end dates are provided
          this.filterStartDate = dates[0];
          this.filterEndDate = dates[1];
        } else {
          // Handle invalid date range format
          console.error('Invalid date range format');
          // You can choose to handle this case differently based on your requirements
        }
      }
    },
    async getDepartmentWiseAssignEmployee(deptId) {
      return await this.$api.get(`api/department/${deptId}/users`);
    },
    setDialCodeAsCountry(countryId) {
      this.dial_code_country_id = countryId;
    },
    formatFnTableLastContactDate(value) {
      if (value) {
        return this.$moment(value).format("MMM Do YYYY");
      }
      return "Not Set";
    },
    onContextLastContactDate(ctx) {
      this.last_contact_date = ctx.selectedYMD;
    },

    showModal() {
      if(this.modelType !== "editModel"){
        this.assign_users = [this.authUser.id]
      }

      this.$bvModal.show("modal-lead-form");
      this.loadAllStatus();
    },

    showEstimationModal(value) {
      this.selectedLeadInfo = {
        leadId: value?.id,
        departmentId: value?.department_id,
      };

      this.leadTitle = value?.title;
      // this.estimationleadId = value?.id;
      // this.departmentId = value?.department_id;

      this.$bvModal.show("modal-financial-estimation-show-form-on-lead");
    },

    hiddenModal() {
      this.modelType = "";
      this.$bvModal.hide("modal-lead-form");
      this.resetModal();
    },
    resetModal() {
      this.leadId = "";
      this.lead_category_id = "";
      this.lead_source_id = "";
      this.department_id = "";
      this.lead_status_id = "";
      this.assign_users = [];
      this.title = "";
      this.keyContactPerson = "";
      this.company_name = "";
      this.dial_code = "";
      this.country_id = "";
      this.email = "";
      this.mobile = "";
      this.phone = "";
      this.address = "";
      this.city = "";
      this.state = "";
      this.street = "";
      this.zip_code = "";
      this.note = "";
      this.website_link = "";
      this.last_contact_date = "";
      this.business_card_front = "";
      this.business_card_back = "";
      this.business_card_front_input = null;
      this.business_card_back_input = null;
    },
    onShowPage(id) {
      this.$router.push({
        name: "admin-lead-details",
        params: { id },
      });
    },
    async onCopy(value) {
      this.modelType = "copyModel";

      let dialCodeValue = value?.dialCodeCountry?.data?.dial_code;
      let mobile = value?.mobile
          ? value?.mobile.split(dialCodeValue)
          : value?.mobile;

      this.leadId = value?.id;
      this.lead_category_id = "";
      this.lead_source_id = "";
      this.department_id = "";
      this.lead_status_id = "";
      this.assign_users = [this.authUser.id];
      this.title = value?.title;
      this.keyContactPerson = value?.key_contact_person;
      this.company_name = value?.company_name;
      this.country_id = value?.country_id;
      this.email = value?.email;
      this.dial_code_country_id = value?.dial_code_country_id;
      this.mobile = value?.mobile ? mobile[1] : value?.mobile;
      this.phone = value?.phone;
      this.address = value?.address;
      this.city = value?.city;
      this.state = value?.state;
      this.street = value?.street;
      this.zip_code = value?.zip_code;
      this.note = value?.note;
      this.website_link = value?.website_link;
      this.last_contact_date = value?.last_contact_date;

      this.loadAllStatus();

      this.showModal();
    },
    async loadAvailableStatus() {
      const res = await this.$api.get(
          `/api/lead-status/${this.lead_status_id}`
      );
      const status = await this.$api.get("api/lead-status/active-all");
      const existingStatus = res?.data?.data;
      let availableStatus = [];
      availableStatus = (status?.data?.data || []).filter((item) => {
        if (item?.sequence >= existingStatus?.sequence) {
          let name = item.name;
          return {
            name,
            id: item.id,
          };
        }
      });

      this.statusIdOption = availableStatus;
      this.statusIdOption = (availableStatus || []).map((item) => {
        let position = "";

        if (item?.type == 2) {
          position = "(Success)";
        } else if (item?.type == 3) {
          position = "(Dead)";
        }
        let name = `${item?.name} ${position}`;
        return {
          name,
          id: item.id,
        };
      });
    },
    async loadAllStatus() {
      //status
      const status = await this.$api.get("api/lead-status/active-all");
      this.statusIdOption = (status?.data?.data || []).map((item) => {
        let position = "";

        if (item?.type == 2) {
          position = "(Success)";
        } else if (item?.type == 3) {
          position = "(Dead)";
        }
        let name = `${item?.name} ${position}`;
        return {
          name,
          id: item.id,
        };
      });
    },
    async onShow(value) {
      this.modelType = "editModel";

      let dialCodeValue = value?.dialCodeCountry?.data?.dial_code;
      let mobile = value?.mobile
          ? value?.mobile.split(dialCodeValue)
          : value?.mobile;

      this.leadId = value?.id;
      this.lead_category_id = +value?.lead_category_id;
      this.lead_source_id = +value?.lead_source_id;
      this.department_id = +value?.department_id;
      // this.lead_status_id = +value?.lead_status_id;
      // this.user_id = +value?.user_id;
      this.assign_users = (value?.team.data || []).map(
          (item) => item?.id
      );
      this.title = value?.title;
      this.keyContactPerson = value?.key_contact_person;
      this.company_name = value?.company_name;
      this.country_id = value?.country_id;
      this.email = value?.email;
      this.dial_code_country_id = value?.dial_code_country_id;
      this.mobile = value?.mobile ? mobile[1] : value?.mobile;
      this.phone = value?.phone;
      this.address = value?.address;
      this.city = value?.city;
      this.state = value?.state;
      this.street = value?.street;
      this.zip_code = value?.zip_code;
      this.note = value?.note;
      this.website_link = value?.website_link;
      this.last_contact_date = value?.last_contact_date;

      this.business_card_front = value?.business_card_front;
      this.business_card_back = value?.business_card_back;

      // this.loadAvailableStatus();

      this.showModal();
    },

    async onDelete(id) {
      this.$swal({
        title: "Warning!",
        text: "Are You Sure You Want To Delete This?",
        icon: "warning",
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-outline-danger ml-1",
        },
        buttonsStyling: false,
        showCancelButton: true,
        confirmButtonText: "Ok",
        showLoaderOnConfirm: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          try {
            await this.$api.delete(`/api/leads/${id}`);

            this.loadItems();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Success",
                icon: "BellIcon",
                variant: "success",
                text: "Lead Deleted Successfully ",
              },
            });
          } catch (error) {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: "Error",
                icon: "BellIcon",
                variant: "danger",
                text: error?.response?.data?.message,
              },
            });
          }
        }
      });
    },

    onSearch() {
      if (this.delayTimer) {
        clearTimeout(this.delayTimer);
        this.delayTimer = null;
      }

      this.delayTimer = setTimeout(() => {
        // this.$store.dispatch("leadModule/setFilter", {
        //   key: "searchTerm",
        //   value: this.searchTerm,
        // });

        this.loadItems();
      }, 1000);
    },

    filterLeadsByDate() {
      // this.$store.dispatch("leadModule/setFilter", {
      //   key: "filterStartDate",
      //   value: this.filterStartDate,
      // });
      // this.$store.dispatch("leadModule/setFilter", {
      //   key: "filterEndDate",
      //   value: this.filterEndDate,
      // });
      this.loadItems();
    },
    resetFilterLeadsByDate() {
      this.filterStartDate = "";
      this.filterEndDate = "";

      this.loadItems();
    },

    loadSelecterCountryLeads() {
      try {
        // this.$store.dispatch("leadModule/setFilter", {
        //   key: "filterCountryId",
        //   value: this.filterCountryId,
        // });
        this.loadItems();
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    loadSelectedDepartmentLeads() {
      try {
        // this.$store.dispatch("leadModule/setFilter", {
        //   key: "filterDepartmentId",
        //   value: this.filterDepartmentId,
        // });
        this.loadItems();
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    loadSelectedCategoryLeads() {
      try {
        // this.$store.dispatch("leadModule/setFilter", {
        //   key: "filterCategoryId",
        //   value: this.filterCategoryId,
        // });
        this.loadItems();
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    loadSelectedEmpLeads() {
      try {
        // this.$store.dispatch("leadModule/setFilter", {
        //   key: "filterEmpId",
        //   value: this.filterEmpId,
        // });
        this.loadItems();
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    loadSelectedStatusLeads() {
      try {
        // this.$store.dispatch("leadModule/setFilter", {
        //   key: "filterStatusId",
        //   value: this.filterStatusId,
        // });
        this.loadItems();
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    loadSelectedSourceLeads() {
      try {
        // this.$store.dispatch("leadModule/setFilter", {
        //   key: "filterSourceId",
        //   value: this.filterSourceId,
        // });

        this.loadItems();
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    updateParams(newProps) {
      this.serverParams = Object.assign({}, this.serverParams, newProps);
    },

    onPageChange(params) {
      this.updateParams({ page: params.currentPage });
      this.loadItems();
    },

    onPerPageChange(params) {
      this.updateParams({ perPage: params.currentPerPage });
      this.loadItems();
    },

    onSortChange(params) {
      this.params = (params || []).map((item) => {
        let field = item.field;

        if (item.field == "format_title") {
          field = "title";
        }

        if (item.field == "format_email") {
          field = "email";
        }

        return {
          field: field,
          type: item.type,
        };
      });

      this.updateParams({
        sort: this.params,
      });
      this.loadItems();
    },

    onColumnFilter(params) {
      this.updateParams(params);
      this.loadItems();
    },
    // Export Lead File
    async exportLead() {
      try {
        const params = {
          title: this.searchTerm,
          source_id: this.sourceId,
          status_id: this.statusId,
          category_id: this.categoryId,
          start_date: this.filterStartDate,
          end_date: this.filterEndDate,
          assign_users: this.empId,
          department_id: this.departmentId,
          country_id: this.filterCountryId,
        };
        const { data } = await this.$api.post(`/api/leads-export`, params);
        window.location.href = data.data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },
    async getLeads(params) {
      return await this.$api.get(
          "api/leads?include=user,leadCategory,leadSource,leadStatus,department,activityLogs,leadCountry,dialCodeCountry,team",
          {
            params: {
              show: params.show,
              page: params.page,
              sort: params.sort,
              q: params.q,
              filterSource: params.filterSource,
              filterStatus: params.filterStatus,
              filterCategory: params.filterCategory,
              filterStartDate: params.filterStartDate,
              filterEndDate: params.filterEndDate,
              filterEmp: this.authUser.id,
              filterDepartment: params.filterDepartment,
              filterCountry: params.filterCountry,
            },
          }
      );
    },
    async getLeadStatusInfo() {
      return await this.$api.get("api/lead-status/active-all");
    },
    async getLeadCategories() {
      return await this.$api.get("api/lead-categories/active-all");
    },
    async getLeadSources() {
      return await this.$api.get("api/lead-sources/active-all");
    },
    async getDepartments() {
      return await this.$api.get("api/departments/all");
    },
    async getLeadStatus() {
      return await this.$api.get("api/lead-status/active-all");
    },

    async getCountries() {
      return await this.$api.get("api/country/all");
    },

    async getUsers() {
      return await this.$api.get("api/users/active-all");
    },
    updateLeadInfo: function () {
      this.loadItems();
      this.showFinancialEstimationComponent = false;
    },
    async loadItems() {
      try {
        this.handleDateRangeChange()

        const [leads] = await Promise.all([
          this.getLeads({
            show: this.serverParams.perPage,
            page: this.serverParams.page,
            sort: this.serverParams.sort,
            q: this.searchTerm,
            filterSource: this.filterSourceId,
            filterStatus: this.filterStatusId,
            filterCategory: this.filterCategoryId,
            filterStartDate: this.filterStartDate,
            filterEndDate: this.filterEndDate,
            filterEmp: this.filterEmpId,
            filterDepartment: this.filterDepartmentId,
            filterCountry: this.filterCountryId,
          }),
        ]);

        const data = leads?.data?.data;

        const meta = leads?.data?.meta;
        this.totalRecords = meta?.pagination?.total;

        this.rows = data;
      } catch (error) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: "Warning",
            icon: "BellIcon",
            variant: "warning",
            text: error?.response?.data?.message,
          },
        });
      }
    },

    // Lead Submit Form
    validationForm: async function () {
      this.$refs.leadValidation.validate().then(async (success) => {
        if (success) {
          try {

            this.$refs.leadValidation.reset();
            if (this.modelType == "editModel") {
              this.isLeadsFormSubmitLoading = true;
              const formData = new FormData();

              // Append regular fields
              formData.append("_method", "PUT");
              formData.append("lead_category_id", this.lead_category_id);
              formData.append("lead_source_id", this.lead_source_id);
              formData.append("department_id", this.department_id);
              formData.append("title", this.title);
              formData.append("key_contact_person", this.keyContactPerson);
              formData.append("company_name", this.company_name);
              formData.append("dial_code_country_id", this.dial_code_country_id);
              formData.append("country_id", this.country_id);
              formData.append("email", this.email);
              formData.append("mobile", this.mobile);
              formData.append("phone", this.phone);
              formData.append("address", this.address);
              formData.append("city", this.city);
              formData.append("state", this.state);
              formData.append("street", this.street);
              formData.append("zip_code", this.zip_code);
              formData.append("note", this.note);
              formData.append("website_link", this.website_link);

              // Append array fields
              this.assign_users.forEach((user, index) => {
                formData.append(`assign_users[${index}]`, user);
              });

              // Append file fields, if they exist
              if (this.business_card_back_input) {
                formData.append("business_card_back", this.business_card_back_input);
              }
              if (this.business_card_front_input) {
                formData.append("business_card_front", this.business_card_front_input);
              }

              // Send the FormData with PUT request
              await this.$api.post(`/api/leads/${this.leadId}`, formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                }
              });

              this.isLeadsFormSubmitLoading = false;
              try {
                const [leadStatus] = await Promise.all([
                  this.getLeadStatusInfo(),
                  this.loadItems(),
                ]);
                this.leadStatusInfo = (leadStatus?.data?.data || []).map(
                    (item) => {
                      let id = item.id;
                      let name = item.name;
                      let leadCount = item.leads_count;
                      let leadColorCode = item.color_code;

                      return {
                        id: id,
                        name: name,
                        leadCount: leadCount,
                        leadColorCode: leadColorCode,
                      };
                    }
                );
              } catch (error) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error?.response?.data?.message,
                  },
                });
              }

              this.loadItems();
              this.hiddenModal();
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Lead Updated Successfully",
                },
              });
            } else if (this.modelType == "copyModel") {
              this.isLeadsFormSubmitLoading = true;
              const formData = new FormData();
              formData.append("lead_category_id", this.lead_category_id);
              formData.append("lead_source_id", this.lead_source_id);
              formData.append("department_id", this.department_id);
              formData.append("lead_status_id", this.lead_status_id);
              this.assign_users.forEach((user, index) => {
                formData.append(`assign_users[${index}]`, user);
              });
              formData.append("title", this.title);
              formData.append("key_contact_person", this.keyContactPerson);
              formData.append("dial_code_country_id", this.dial_code_country_id);
              formData.append("company_name", this.company_name);
              formData.append("country_id", this.country_id);
              formData.append("email", this.email);
              formData.append("mobile", this.mobile);
              formData.append("phone", this.phone);
              formData.append("address", this.address);
              formData.append("city", this.city);
              formData.append("state", this.state);
              formData.append("street", this.street);
              formData.append("zip_code", this.zip_code);
              formData.append("note", this.note);
              formData.append("website_link", this.website_link);

              // Append image files if they exist
              if (this.business_card_front_input) {
                formData.append("business_card_front", this.business_card_front_input);
              }
              if (this.business_card_back_input) {
                formData.append("business_card_back", this.business_card_back_input);
              }

              // Send the FormData via POST request
              await this.$api.post("/api/leads/copy", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });


              this.isLeadsFormSubmitLoading = false;
              this.hiddenModal();
              try {
                const [leadStatus] = await Promise.all([
                  this.getLeadStatusInfo(),
                  this.loadItems(),
                ]);
                this.leadStatusInfo = (leadStatus?.data?.data || []).map(
                    (item) => {
                      let id = item.id;
                      let name = item.name;
                      let leadCount = item.leads_count;
                      let leadColorCode = item.color_code;

                      return {
                        id: id,
                        name: name,
                        leadCount: leadCount,
                        leadColorCode: leadColorCode,
                      };
                    }
                );
              } catch (error) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error?.response?.data?.message,
                  },
                });
              }
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Lead Copied Successfully ",
                },
              });
            } else {
              this.isLeadsFormSubmitLoading = true;

              // Create a new FormData object
              const formData = new FormData();
              formData.append("lead_category_id", this.lead_category_id);
              formData.append("lead_source_id", this.lead_source_id);
              formData.append("department_id", this.department_id);
              formData.append("lead_status_id", this.lead_status_id);
              this.assign_users.forEach((user, index) => {
                formData.append(`assign_users[${index}]`, user);
              });
              formData.append("title", this.title);
              formData.append("key_contact_person", this.keyContactPerson);
              formData.append("dial_code_country_id", this.dial_code_country_id);
              formData.append("company_name", this.company_name);
              formData.append("country_id", this.country_id);
              formData.append("email", this.email);
              formData.append("mobile", this.mobile);
              formData.append("phone", this.phone);
              formData.append("address", this.address);
              formData.append("city", this.city);
              formData.append("state", this.state);
              formData.append("street", this.street);
              formData.append("zip_code", this.zip_code);
              formData.append("note", this.note);
              formData.append("website_link", this.website_link);

              // Append the image files if they are present
              if (this.business_card_front_input) {
                formData.append("business_card_front", this.business_card_front_input);
              }
              if (this.business_card_back_input) {
                formData.append("business_card_back", this.business_card_back_input);
              }

              // Send the request
              await this.$api.post("/api/leads", formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                },
              });

              this.isLeadsFormSubmitLoading = false;
              this.hiddenModal();
              try {
                const [leadStatus] = await Promise.all([
                  this.getLeadStatusInfo(),
                  this.loadItems(),
                ]);
                this.leadStatusInfo = (leadStatus?.data?.data || []).map(
                    (item) => {
                      let id = item.id;
                      let name = item.name;
                      let leadCount = item.leads_count;
                      let leadColorCode = item.color_code;

                      return {
                        id: id,
                        name: name,
                        leadCount: leadCount,
                        leadColorCode: leadColorCode,
                      };
                    }
                );

              } catch (error) {
                this.$toast({
                  component: ToastificationContent,
                  props: {
                    title: "Error",
                    icon: "BellIcon",
                    variant: "danger",
                    text: error?.response?.data?.message,
                  },
                });
              }

              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Success",
                  icon: "BellIcon",
                  variant: "success",
                  text: "Lead Created Successfully ",
                },
              });
            }

          } catch (error) {
            this.isLeadsFormSubmitLoading = false;

            if (error?.response?.data?.message) {
              this.$toast({
                component: ToastificationContent,
                props: {
                  title: "Error",
                  icon: "BellIcon",
                  variant: "danger",
                  text: error?.response?.data?.message,
                },
              });
            }

            if (error?.response?.data?.errors) {
              this.$refs.leadValidation.setErrors(
                  error?.response?.data?.errors
              );
            }
          }
        }
      });
    },
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-good-table.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
@import "@core/scss/vue/pages/ui-feather.scss";

.table-custom-style {
  font-size: 13px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: left !important;
    text-align: left !important;
  }
}

.custom-font {
  font-size: 13px !important;
}
.custom-font-validation {
  font-size: 11px;
}
.cell-with-description div {
  margin: 2px 0px;
}
.required-label label::after {
  content: " *";
  color: red;
}
.table-custom-style {
  font-size: 14px !important;
  white-space: nowrap !important;
  min-height: 140px !important;
  tr,
  th,
  td {
    vertical-align: middle !important;
  }
}
.custom-font {
  font-size: 13px !important;
}
.small-image {
  max-width: 150px; /* Limits the width to make the image smaller */
  height: auto; /* Maintains the aspect ratio */
}
</style>
